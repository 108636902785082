@use "../config/" as *;
.webview{
    .waf-academy{
        @extend %pb-0;
        @extend %px-3;
        .waf-head{
            @extend %mx-3-neg;
            @extend %px-3;
            &::before{
               @include background(null, "pattern/mobile/academy-head-webview-bg.png", center bottom/100% 100% no-repeat);
            }
            .title{
                width: max-content;
                margin-inline: auto;
                border-block:0.05rem solid var(--accent-100);
                @extend %mb-4;
                @extend %py-1;
                @extend %font-20-qb;
                span{
                    @extend %font-20-qb;
                }
            }
        }
        .register{
            &-wrapper{
                @extend %flex-c-n;
            }
            &-content {
                .text {
                    line-height: 2.4rem;
                    @extend %font-14-tr;
                }
            }
        }
        .section-conent {
            .text,li{
                line-height: 2.4rem;
                @extend %font-14-tr;
            }
        }
        .section-venue{
            @extend %py-4;
            @extend %mx-3-neg;
            &::before{
                @include background(var(--neutral-5), "cssimages/svg/vector.svg", center / contain no-repeat);
            }
            .head{
                .title{
                    width: max-content;
                    margin-inline: auto;
                    border-block:0.05rem solid var(--accent-100);
                    @extend %py-1;
                    @extend %font-20-qb;
                    @extend %secondary-900;
                    @extend  %uppercase;
                    
                }
            }
        }
        .venue{
            &-list{
                padding-top: 0;
                @extend %px-3;
            }
            &-item {
                border-radius: unset;
                padding-bottom: 0;
                box-shadow: .1rem .6rem .7rem 0 clr(neutral-800,1);
                &::after,&::before{
                    content: unset;
                }
                .title{
                    @extend %pb-4;
                    @extend %font-22-qb;
                    @extend  %uppercase;
                    &::before{
                        content: unset;
                    }
                    &::after{
                        @include background(var(--secondary-900), "pattern/academy-venue-title-mweb-bg.png", top/100% 100%);
                    }
                }
            }
            &-address{
                .text{
                    font: 1.4rem/2.2rem $font-tertiary;
                    font-weight: 700;
                }
                .sub-title{
                    border-radius:unset;
                    @extend  %uppercase;
                    @extend %font-12-qm;
                    @extend %neutral-800;
                    @extend %primary-100-bg;
                    @extend %relative;
                    @extend %px-3;
                    &::before {
                        content: '';
                        width: 100%;
                        height: .1rem;
                        z-index: var(--z-patterns);
                        @extend %pos-center;
                        @extend %primary-100-bg;
                    }
                }
            }
            &-address-wrapper{
                &::before{
                    background: var(--primary-100);
                    @extend %w-60;    
                }
            }
        }
        .footer{
            @extend %text-center;
            @extend %pt-4;
            .sub-title{
                font: 1.2rem/2.2rem $font-tertiary;
                font-weight: 700;
                @extend %w-70;
                @extend %mx-auto;
            }
        }
        .section-achievements{
            @include background(var(--secondary-900), "pattern/mobile/academy-achievement-webview-bg.png", center top/cover);
            @extend %mx-3-neg;
            @extend %relative;
            @extend %py-8;
            &::before{
                content: unset;
            }
        }
        .achievement-title{
            width: max-content;
            margin-inline: auto;
            border-block:0.05rem solid var(--accent-100);
            @extend %mb-4;
            @extend %py-1;
            @extend %mb-3;
            @extend %font-20-qb;
            @extend %uppercase;
            &::before{
                content: unset;
            }
        }
      
        .tab{
            &s{
                gap: unset;
                @extend %mb-6;
            }
            &-name{
                border-radius: unset;
                @extend %secondary-800-bg;
                &.active{
                    @extend %accent-100-bg;
                }
            }
            &-text{
                @extend %font-12-qb;
                @extend %neutral-50;
            }
            
        }
        .achievements{
            &-list{
                width: auto;
                @extend %px-3;
            }
            &-thumbnail{
               border: 0.2rem solid var(--pure-white-900);
            }
            &-item {
                border-radius: unset;
                &::before {
                    border-radius: unset;
                    border: .1rem solid var(--secondary-400);
                    background: linear-gradient(91.83deg, var(--secondary-800) 0%, var(--spider-1s) 100%);
                    mask-image:unset;
                }
            }
            &-content{
                .title{
                    @extend %accent-100;
                    @extend %font-14-qb;
                    @extend %uppercase;
                    &::after{
                        @extend %primary-500-bg;
                    }
                }
            }
            &-details{
                .text{
                    line-height: 2rem;
                    @extend %pure-white-900;
                    @extend %font-12-tr;
                }
            }
        }
        .section-fees-structure{
            @extend %py-8;
            @extend %pure-white-900-bg;
            @extend %mx-3-neg;
            @extend %px-3;
            .title{
                width: max-content;
                margin-inline: auto;
                border-block:0.05rem solid var(--accent-100);
                @extend %py-1;
                @extend %font-20-qb;
                @extend %secondary-900;
                @extend  %uppercase;
            } 
        }
        .accordion{
            &-body-wrap{
                border-top: .1rem solid var(--neutral-1000);
            }
            &-list{
                @extend %mt-1;
            }
            &-item{
                margin-bottom: 0;
                padding-inline: 0;
                border-bottom: .1rem solid var(--neutral-300);
                p.text{
                    line-height: 2.4rem;
                    @extend %neutral-800;
                    @extend %font-14-tr;
                }
            }
            &-btn[aria-expanded="false"]{
                .btn-icon{
                    &::after,&::before{
                        @extend %neutral-500-bg;
                    }
                }
            }
            &-head {
                .btn-text{
                    @extend %uppercase;
                    @extend %font-16-qb;
                }
                .btn-icon{
                    @extend %transparent-bg;
                    &::after{
                        @extend %accent-900-bg;
                    }
                }
            }
        }
        .table{
            &-head{
                .table-data{
                    background: var(--secondary-900);
                    @extend %font-12-qb;
                    &:first-child{
                        border-top-left-radius:unset
                    }
                    &:last-child{
                        border-top-right-radius:unset;
                    }
                }
            }
            &-row{
                &:last-child{
                    .table-data{
                        &::before{
                            content: unset;
                        }
                    }
                }
            }
            &-body{
                .table-data{                
                    @extend %neutral-100-bg;
                    &.academy-name{
                        .text{
                            @extend %uppercase;
                            @extend %neutral-700;
                            @extend %font-12-qb;
                        }
                        
                    }
                    .text{
                        @extend %font-14-qm;
                        @extend %neutral-800;
                    }
                   
                }
               
            }
        }
        .text-strip {
            background: var(--secondary-900);
            @extend %font-16-qb;
        }
    }
    .waf-photos-listing{
        padding-bottom:9rem;
        padding-inline: 0;
        margin-bottom: 0;
        @extend %pt-8;
        &::before {
            @include background(var(--secondary-900), "pattern/mobile/academy-achievement-webview-bg.png", bottom center/cover no-repeat);
        }
        .waf-head{
            @extend %mb-4;
        }
        .head-wrap{
            .title{
                width: max-content;
                margin-inline: auto;
                border-block:0.05rem solid var(--accent-100);
                @extend %py-1;
                @extend %font-20-qb;
                @extend  %uppercase;
            }
        }
        .head-tab{
            transform: translateX(-50%);
            @include position(null,null,3rem,50%);
            a{
                border-radius: unset;
                @extend %font-16-qm;
                @extend %accent-100-bg;
                @extend %pure-white-900;
            }
        }
        .item-type-icon{
            @extend %d-none;
        }
        .social-share{
            @extend %mb-4;
        }
        .article{
            &-title{
                @extend %font-16-qb;
                @extend %uppercase;
            }
            &-list{
                @extend %flex;
                @extend %x-scroll;
                @extend %gap-3;
                @extend %px-3;
            }
            &-meta{
                .meta-date{
                    @extend %font-12-tr;
                    @extend %mt-5;
                    @extend %mb-4;
                    @extend %secondary-200;
                }
            }
            &-item{
                width: 80%;
                flex-shrink: 0;
                border-bottom: .1rem solid var(--primary-1000);
                border-radius: unset;
            }
            &-content{
                padding: 0;
                @extend %mt-4;
                @extend %transparent-bg;
            }
            
        }
    }
}