@use "../config/" as *;
.waf-academy {
    @extend %pt-0;
    .waf-head {
        padding-bottom: 13rem;
        @extend %pt-6;
        @extend %px-2;
        @extend %relative;
        @extend %mx-2-neg;
        .title {
            @extend %pb-4;
            @extend %uppercase;
            @extend %pure-white-900;
            @extend %font-24-pm;
            span {
                @extend %pl-1;
                font-weight: 700;
            }
        }
        &::before {
            content: '';
            z-index: var(--z-patterns);
            pointer-events: none;
            @extend %pos-tl;
            @extend %w-100;
            @extend %h-100;
            @include background(null, "pattern/mobile/academy-head-bg.png", center top/cover);
        }
    }
    .waf-body{
        margin-top: -13rem;
    }
    .social {
        &-wrap {
            @extend %gap-3;
            @extend %flex;
        }
        &-link-icon {
            width: 3rem;
            height: 3rem;
            aspect-ratio: 1/1;
            @extend %secondary-800-bg;
            @extend %circle-radius;
            @extend %flex-c-c;
            @extend %font-0;
        }
        &-link-facebook,
        &-link-instagram {
            &::before {
                font-family: $font-icon;
                font-size: 1.4rem;
                @extend %pure-white-900;
            }
        }
    }
    .register {
        &-thumbnail {
            width: 18rem;
        }
        &-content {
            @extend %pt-4;
            .text {
                @extend %pb-4;
                @extend %pure-white-900;
                @extend %font-16-pm;
            }
        }
        &-wrapper {
            @extend %relative;
            @extend %py-4;
            @extend %gap-4;
            @include flex-config(flex, row-reverse, flex-end, flex-end);
            &::before,
            &::after {
                content: '';
                height: .1rem;
                pointer-events: none;
                @extend %w-100;
                @extend %primary-600-bg;
            }
            &::before {
                @extend %pos-tl;
            }
            &::after {
                @extend %pos-bl;
            }
        }
    }
    .section-video {
        flex-wrap: wrap;
        @extend %pb-3;
        @extend %gap-3;
        @extend %flex;
     
    }
    .embed-responsive {
        @extend %w-100;
        &-item {
            border-radius: var(--half-radius);
        }
    }
    .video {
        &-left {
            order: 1;
        }
        &-left,
        &-right {
            width: calc(50% - .75rem);
            img {
                aspect-ratio: 2/3;
                border-radius: var(--half-radius);
            }
        }
    }
    .section-conent {
        .text,li {
            @extend %pb-4;
            @extend %neutral-800;
            @extend %font-14-pm;
        }
    }
    .section-venue {
        @extend %py-6;
        @extend %mx-2-neg;
        @extend %relative;
        &::before {
            content: '';
            width: var(--window-inner-width);
            height: 100%;
            pointer-events: none;
            @extend %pos-tl;
            @include background(null, "pattern/white-bg.png", center top/cover);
        }
        .head {
            @extend %pb-3;
            @extend %px-2;
            @extend %relative;
            .title {
                @extend %capitalize;
                @extend %neutral-800;
                @extend %font-18-pb;
            }
        }
      
    }
    .swiper-button {
        @extend %d-none;
    }
    .venue {
        &-list {
            overflow-x: auto;
            padding-top: 6rem;
            @extend %px-2;
            @extend %gap-3;
        }
        &-item {
            border-radius: 0 0 var(--full-radius) var(--full-radius);
            width: 80%;
            height: auto;
            @extend %pure-white-900-bg;
            @extend %relative;
            &::before,
            &::after {
                content: '';
                pointer-events: none;
                width: 4rem;
                height: 7rem;
            }
            &::before {
                @include position(-7rem, null, null, -.3rem);
                @include background(null, "pattern/academy-venue-left.png", bottom/100% no-repeat);
            }
            &::after {
                @include position(-7rem, 0, null, null);
                @include background(null, "pattern/academy-venue-right.png", bottom/100% no-repeat);
            }
            &:last-child {
                @extend %mr-4;
            }
            .title {
                height: 15rem;
                isolation: isolate;
                @extend %mb-4;
                @extend %text-center;
                @extend %flex-c-c;
                @extend %pure-white-900;
                @extend %px-6;
                @extend %relative;
                @extend %font-18-pb;
                @extend %capitalize;
                &::before {
                    content: '';
                    width: calc(100% - 6.5rem);
                    height: 3rem;
                    pointer-events: none;
                    @include position-combo(x-center,-1.7rem);
                    @include background(null, "pattern/academy-venue-center.png", top/100% no-repeat);
                }
                &:after {
                    content: '';
                    z-index: var(--z-patterns);
                    pointer-events: none;
                    @include position-combo(x-center,0);
                    @include background(null, "pattern/academy-venue-title-bg.png", top/100% 100%);
                    @extend %w-100;
                    @extend %h-100;
                }
            }
        }
        &-content {
            @extend %flex-column;
            @extend %text-center;
            @extend %px-4;
        }
        &-address {
            @extend %pb-4;
            .sub-title {
                border-radius: .3rem;
                @extend %px-2;
                @extend %py-1;
                @extend %primary-700-bg;
                @extend %capitalize;
                @extend %font-12-pm;
                @extend %pure-white-900;
            }
            .text {
                height: max-content;
                @extend %capitalize;
                @extend %mt-2;
                @extend %neutral-800;
                @extend %font-16-pm;
            }
            &-wrapper {
                isolation: isolate;
                @extend %relative;
                &::before {
                    content: '';
                    width: 100%;
                    height: .1rem;
                    background: linear-gradient(90deg, clr(neutral-900, 0) 0%, var(--neutral-900) 49.5%, clr(neutral-900, 0) 100%);
                    z-index: var(--z-patterns);
                    @extend %pos-center;
                }
            }
        }
    }
    .footer {
        @extend %relative;
        @extend %pt-3;
        @extend %px-2;
        .sub-title {
            @extend %neutral-900;
            @extend %font-12-pb;
        }
    }
    .section-achievements {
        @extend %pt-8;
        @extend %pb-6;
        @extend %relative;
        &::before {
            content: '';
            width: var(--window-inner-width);
            z-index: var(--z-patterns);
            pointer-events: none;
            @extend %h-100;
            @include position(0, null, null, var(--space-2-neg));
            @include background(null, "pattern/mobile/academy-achievement-bg.png", center top/cover);
        }
    }
    .achievement-title {
        @extend %pb-3;
        @extend %text-center;
        @extend %capitalize;
        @extend %font-18-pb;
        @extend %pure-white-900;
        @extend %relative;
        &::before {
            content: '';
            height: 3.5rem;
            bottom: var(--space-4);
            @extend %w-100;
            @extend %pos-x-center;
            @include background(null, "pattern/mobile/achievements.png", center top/cover);
        }
    }
    .tab {
        &s {
            @extend %mb-4;
            @extend %gap-2;
            @extend %flex;
        }
        &-name {
            height: 3.6rem;
            flex: 1;
            border-radius: .3rem;
            cursor: pointer;
            @extend %text-center;
            @extend %flex-c-c;
            @extend %primary-50-bg;
            &.active {
                @extend %tertiary-600-bg;
            }
        }
        &-text {
            @extend %neutral-800;
            @extend %uppercase;
            @extend %font-12-pr;
        }
        &-container-wrap {
           @extend %d-none;
            &.active {
                @extend %d-block;
            }
        }
    }
    .achievements {
        &-list {
            padding-top: 5rem;
            overflow-x: auto;
            @extend %gap-2;
        }
        &-item {
            border-radius: var(--half-radius);
            width: 65%;
            min-height: 17rem;
            @extend %relative;
            @extend %px-2;
            @extend %pb-2;
            &::before {
                content: '';
                z-index: var(--z-patterns);
                border-radius: var(--half-radius);
                mask-image: linear-gradient(180deg, var(--primary-700) 0%, clr(primary-700, 2) 71.5%, clr(primary-700, 0) 100%);
                border: .1rem solid var(--primary-500);
                @extend %pos-tl;
                @extend %primary-700-bg;
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-thumbnail {
            width: 10rem;
            height: 10rem;
            aspect-ratio:1/1;
            margin-top: -5rem;
            @extend %mb-2;
            @extend %mx-auto;
            @extend %circle-radius;
            img {
                @extend %circle-radius;
            }
        }
        &-content {
            .title {
                @extend %pb-2;
                @extend %mb-2;
                @extend %relative;
                @extend %text-center;
                @extend %font-14-pb;
                @extend %tertiary-600;
                &:after {
                    content: '';
                    height: .1rem;
                    width: calc(100% - var(--space-4));
                    background: linear-gradient(90deg, transparent 0%, var(--primary-500) 50.5%, transparent 100%);
                    @include position-combo(x-center,null,0);
                    @extend %pos-x-center;
                    @extend %w-100;
                }
            }
        }
        &-details {
            @extend %text-center;
            .text {
                line-height: 1.6rem;
                @extend %capitalize;
                @extend %font-12-pb;
                @extend %primary-50;
            }
        }
    }
    .section-fees-structure {
        @extend %py-6;
        .title {
            @extend %pb-2;
            @extend %capitalize;
            @extend %neutral-800;
            @extend %font-18-pb;
        }
    }
    .table-data{
        height: unset;
        @extend %py-4;
    }
    .table{
        overflow-x: auto;
        overflow-y: hidden;
        &-row {
            justify-content: unset;
            
        }
        &-data {
            width: 35%;
            flex-shrink: 0;
            @extend %px-2;
            &.academy-name {
                text-align: left;
                justify-content: flex-start;
                width: 25rem;
            }
        }
        &-head {
            .table-data {
                background: linear-gradient(91.83deg, var(--secondary-800) 0%, var(--primary-700) 100%);
                background-attachment: fixed;
                white-space: nowrap;
                &:first-child {
                    border-top-left-radius: var(--half-radius);
                }
                &:last-child {
                    border-top-right-radius: var(--half-radius);
                }
            }
        }
        &-body {
            .table-row{
                align-items: stretch;
            }
            .table-data {
                height: auto;
                border-bottom: 0.1rem solid var(--neutral-200);
                word-break: break-word;
                @extend %px-2;
                @extend %relative;
                .text {
                    @extend %neutral-800;
                    @extend %font-14-pm;
                }
                &.academy-name {
                    .text {
                        font-weight: 700;
                        @extend %capitalize;
                    }
                }
            }
        }
    }
    .accordion {
        &-item {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            @extend %mb-4;
            @extend %pure-white-900-bg;
            @extend %p-3;
            &:has(.accordion-btn) {
                &[area-expanded=true] {
                    border-radius: var(--half-radius);
                }
            }
            p.text{
                @extend %neutral-800;
                @extend %font-14-pm;
            }
        }
        &-btn {
            @extend %gap-3;
            @extend %w-100;
            @extend %flex-sb-c;
            &[aria-expanded=true] {
                .btn-icon::after {
                    rotate: 0deg;
                }
            }
        }
        &-head {
            .btn-text {
                @extend %text-left;
                @extend %capitalize;
                @extend %neutral-800;
                @extend %font-16-pb;
            }
            .btn-icon {
                width: 3rem;
                height: 3rem;
                aspect-ratio: 1/1;
                flex-shrink: 0;
                @extend %relative;
                @extend %tertiary-600-bg;
                @extend %circle-radius;
                &::after,
                &::before {
                    content: "";
                    height: 0.15rem;
                    position: absolute;
                    inset: 0;
                    @extend %rounded-radius;
                    @extend %m-auto;
                    @extend %w-50;
                    @extend %neutral-900-bg;
                }
                &::after {
                    rotate: 90deg;
                    transition: 300ms rotate;
                }
            }
        }
        &-body-wrap {
            border-top: .1rem solid var(--neutral-200);
            @extend %mt-3;
            @extend %pt-3;
        }
    }
    .text-strip {
        background: linear-gradient(91.83deg, var(--secondary-800) 0%, var(--primary-700) 100%);
        @extend %my-4;
        @extend %neutral-50;
        @extend %p-2;
        p {
            @extend %neutral-50;
            @extend %font-14-pb;
        }
    }
}
.waf-photos-listing {
    @extend %mb-4;
    @extend %py-6;
    @extend %relative;
    &::before {
        content: '';
        width: var(--window-inner-width);
        z-index: var(--z-patterns);
        @extend %h-100;
        @extend %pos-tl;
        @include background(null, "pattern/mobile/academy-photos-bg.png", center top/cover);
    }
    .waf-head {
        --_title-color: var(--pure-white-900);
        @extend %mb-3;
    }
}
@include mq(col-md) {
    .waf-academy {
        .waf-head {
            margin-inline: 0;
            padding-inline: 0;
            padding-top: var(--space-11);
            &::before {
                content: '';
                left: min(var(--container-white-space)*-1, var(--container-white-space));
                width: var(--window-inner-width);
                height: calc(var(--window-inner-height) - var(--header-height) - 10rem);
                @include background(null, "pattern/academy-head-bg.png", center top/cover);
            }
            .title {
                font-size: 3.2rem;
                text-align: center;
            }
           
        }
        .register-wrapper {
            justify-content: center;
        }
        .section-conent {
            .text {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
        .section-venue {
            margin-inline: 0;
            &::before {
                left: min(var(--container-white-space)*-1, var(--container-white-space));
            }
            .head {
                padding-left: 0;
                .title {
                    font-size: 3.2rem;
                }
            }      
        }
        .swiper-button {
            display: flex;
            bottom: 3rem;
            &-prev {
                right: 4rem;
            }
            &-next {
                right: 0;
            }
        }
        .venue {
            &-swiper {
                position: unset;
            }
            &-list {
                overflow-x: unset;
                gap: 0;
                padding-inline: 0;
                padding-top: 8rem;
                padding-bottom: var(--space-4);
            }
            &-item {
                width: 22%;
                &::before {
                    top: -8rem;
                }
                &::after {
                    top: -8rem;
                }
                .title {
                    top: -1.6rem;
                }
            }
        }
        .footer {
            padding-top: 0;
            padding-inline: 0;
            .sub-title {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
        .table-data {
            width: 25%;
            &.academy-name {
                width: 20rem;
            }
        }
        .achievement-title {
            font-size: 3.2rem;
            &::before {
                bottom: var(--space-1);
                height: 8rem;
                z-index: var(--z-patterns);
            }
        }
        .section-achievements {
            .swiper-button{
                bottom: 0;
            }
            &::before {
                left: min(var(--container-white-space)*-1, var(--container-white-space));
                @include background(null, "pattern/academy-achievement-bg.png", center top/cover);
            }
        }
        .achievements {
            &-list {
                gap: 0;
                padding-inline: 0;
                overflow-x: unset;
                padding-bottom: 4rem;
            }
            &-thumbnail {
                margin-bottom: var(--space-4);
            }
            &-item {
                min-height: 19rem;
            }
            &-content {
                .title {
                    font-size: 2rem;
                }
            }
            &-details {
                .text {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-academy {
        .waf-head {
            .title {
                text-align: left;
            }
        }
        .register {
            &-wrapper {
                flex-direction: column;
                padding-block: 0;
                @include position(5.5rem, 0, null, null);
                &::before,
                &::after {
                    content: unset;
                }
            }
            &-thumbnail {
                width: 29rem;
                height: 11rem;
            }
            &-content {
                padding-top: 0;
                width: calc(100% - 32rem);
            }
        }
        .embed-responsive {
            width: 60%;
            height: 100%;
        }
        .video{
            &-right,&-left{
                width: 20%;
                -webkit-mask-size: contain;
                mask-size: contain;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                img {
                    aspect-ratio: unset;
                    height: 48rem;
                }
            }
            &-right {
                -webkit-mask-image: url('/static-assets/images/static/academy-2.png');
                mask-image: url('/static-assets/images/static/academy-2.png');
            }
            &-left {
                order: unset;
                -webkit-mask-image: url('/static-assets/images/static/academy-2.png');
                mask-image: url('/static-assets/images/static/academy-3.png');
            }
        }
        .section-video {
            flex-wrap: nowrap;
            align-items: center;
           
        }
        .section-fees-structure {
            padding-block: 6rem;
            .title {
                padding-bottom: var(--space-4);
                font-size: 3.2rem;
            }
          
        }
        .table-data {
            width: 15%;
           flex: 1;
        }
        .tabs {
            width: 40%;
            margin-inline: auto;
        }
        .achievement-title {
            &::before {
                bottom: var(--space-5-neg);
                height: 11rem;
            }
        }
        .achievements {
            &-item {
                width: 25%;
                min-height: 20rem;
                margin-right: var(--space-3);
            }
            &-thumbnail {
                margin-bottom: var(--space-6);
            }
            &-details {
                .text {
                    line-height: 2rem;
                }
            }
        }
        .section-achievements {
            padding-block: var(--space-12);
        }
    }
    .waf-photos-listing {
        padding-block: var(--space-12) 8rem;
        &::before {
            @include background(null, "pattern/academy-photos-bg.png", bottom center/cover no-repeat);
        }
        .waf-head {
            margin-bottom: var(--space-4);
        }
    }
}
@include mq(col-xxl) {
    .waf-academy {
        .waf-head {
            &::before {
                height: calc(50vh - var(--header-height));
            }
        }
    }
}